import { object, string } from "yup";

export const LabelValueSchema = object({
  label: string().required(),
  value: string(),
});

export const LabelContextSchema = object({
  label: string().required(),
  context: string(),
});

export const ActionButtonSchema = LabelContextSchema.shape({
  alignment: string().oneOf(["LEFT", "CENTER", "RIGHT"]),
  button_color: string(),
  icon: string(),
  label_color: string(),
});
