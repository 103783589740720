import { object, string, boolean, array, type InferType } from "yup";
import { ActionButtonSchema, LabelContextSchema } from "./form";

const MetaTagSchema = object({
  property: string().required(),
  content: string().required(),
});

export const MetaTagsSchema = array().of(MetaTagSchema);

const HeaderSchema = object({
  hotline: object({
    domestic: string().required(),
    abroad_title: string().required(),
    abroad: string().required(),
  }),
  opening_hours: array().of(string().required()).required(),
  action_button: ActionButtonSchema.required(),
});

const MenuItemSchema = object({
  label: string().required(),
  context: string().required(),
  active: boolean().required(),
});

const MainMenuSchema = object({
  items: array().of(MenuItemSchema).required(),
});

const FooterMenuSchema = object({
  title: string().required(),
  items: array().of(LabelContextSchema).required(),
});

const SocialIconSchema = object({
  icon: string().required(),
  link: string().required(),
});

const FooterSchema = object({
  menus: array().of(FooterMenuSchema).required(),
  label_name: string().required(),
  label_button: string().required(),
  label_social: string().required(),
  social_icons: array().of(SocialIconSchema),
});

export const DefaultLayoutSchema = object({
  meta_tags: MetaTagsSchema,
  header: HeaderSchema,
  main_menu: MainMenuSchema,
  footer: FooterSchema,
});

export const BookingLayoutSchema = object({
  meta_tags: MetaTagsSchema,
  footer: FooterSchema,
});

export type DefaultLayout = InferType<typeof DefaultLayoutSchema>;
export type BookingLayout = InferType<typeof BookingLayoutSchema>;
export type MetaTags = InferType<typeof MetaTagsSchema>;
