import type { MetaTags } from "~/schema/layout";

export const setHead = (value: MetaTags) => {
  const route = useRoute();
  const currentTitle =
    route.meta.title ??
    "Kreuzfahrten, Flüge & Hotels buchen bei www.go7seas-kreuzfahrten.de";

  const meta = value.map((val) => {
    return { name: val.property, content: val.content };
  });

  if (!meta.find((item) => item.name === "description")) {
    meta.push({
      name: "description",
      content:
        "Bei go7seas-kreuzfahrten können Sie Seereisen online buchen und Informationen über Ihr Kreuzfahrtschiff finden. Kreuzfahrten, nach Ihrem Wunsch mit oder ohne Anreisepaket, von MSC, Costa, Cunard, AIDA, Royal Caribbean, Princess, Celebrity und anderen Reedereien.",
    });
  }

  if (!meta.find((item) => item.name === "keywords")) {
    meta.push({
      name: "keywords",
      content:
        "Kreuzfahrt, Kreuzfahrten, Seereisen, Schiffsreise, Schiffsreisen, Flusskreuzfahrt, Flusskreuzfahrten, MSC Kreuzfahrten, Costa Kreuzfahrten, Kreuzfahrt Karibik, AIDA, Kreuzfahrt Mittelmeer, Royal Caribbean, Celebrity, Princess, Carnival, Cunard, TUI Cruises",
    });
  }

  const title =
    value.find((item) => item.property === "og:title")?.content ?? currentTitle;
  useHead({
    title,
    meta,
  });
};
