import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin((nuxtApp) => {
  const initializeSentry = () => {
    if (
      useRuntimeConfig().public.sentryEnabled === false ||
      useRuntimeConfig().public.sentryDNS === ""
    ) {
      return;
    }

    const { vueApp } = nuxtApp;
    const status =
      localStorage.getItem("uc_settings") &&
      JSON.parse(localStorage.getItem("uc_settings")).services[3].status;

    if (!Sentry.getClient() && status) {
      Sentry.init({
        app: [vueApp],
        release: "",
        dsn: useRuntimeConfig().public.sentryDNS,
        environment: process.dev ? "development" : "production",
        integrations: [
          new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(
              nuxtApp.$router
            ),
          }),
          new Sentry.Replay(),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        // Session Replay
        replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        beforeSend(event, hint) {
          // Check if it is an exception, and if so, log it.
          if (event.exception) {
            // eslint-disable-next-line no-console
            console.error(
              `[Exeption handled by Sentry]: (${hint.originalException})`,
              { event, hint }
            );
          }
          // Continue sending to Sentry
          return event;
        },
      });

      vueApp.mixin(
        Sentry.createTracingMixins({
          trackComponents: true,
          timeout: 2000,
          hooks: ["activate", "mount", "update"],
        })
      );

      Sentry.attachErrorHandler(vueApp, {
        logErrors: false,
        attachProps: true,
        trackComponents: true,
        timeout: 2000,
        hooks: ["activate", "mount", "update"],
      });

      return {
        provide: {
          sentrySetContext: Sentry.setContext,
          sentrySetUser: Sentry.setUser,
          sentrySetTag: Sentry.setTag,
          sentryAddBreadcrumb: Sentry.addBreadcrumb,
          sentryCaptureException: Sentry.captureException,
        },
      };
    }
  };

  window.addEventListener("UC_UI_CMP_EVENT", function (event) {
    const events = ["ACCEPT_ALL", "DENY_ALL", "SAVE"];
    if (events.includes(event.detail.type)) {
      const status =
        localStorage.getItem("uc_settings") &&
        JSON.parse(localStorage.getItem("uc_settings")).services[3].status;
      if (!status) {
        Sentry.close();
      } else {
        initializeSentry();
      }
    }
  });

  return initializeSentry();
});
